<template>
	<banner-style1 :position="7"></banner-style1>
	<div class="about-contanier">
		<div class="about c w">
			<h3>联系我们</h3>
			<div class="detail">
				<p v-html="data.content"></p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		getPageSelect
	} from '@/api/publish'
	import {
		ref
	} from 'vue'
	let data = ref('')
	getPageSelect({
		id: 7,
		type: 1
	}).then(res => {
		console.log(res);
		data.value = res.data.data[0]
	})
</script>

<style lang="scss" scoped>
	.about-contanier {
		padding-bottom: 200px;

		.about {

			h3 {
				font-size: 28px;
				text-align: center;
				padding: 30px 0;
			}

			.detail {
				border-top: 1px solid #ececec;
				padding: 30px 0;

				.desc {
					margin-bottom: 30px;
				}

				p {
					margin: 5px 0;
				}

				.email {
					margin-top: 30px;
				}
			}

		}
	}
</style>
